import React from 'react'
import Blogdetails01 from '../../assets/img/blog/bigblog.png'
// import Blogdetails02 from '../../assets/img/blog/small.png'

function six() {
  

  return (
    <>
        <div className="col-lg-10">
            <div className="blog-details-wrap">
                <div className="details__content pb-30">
                <h2>Understanding Cancer Genetics: Should You Consider Genetic Testing?</h2>
                <div className="meta-info">
                    <ul>
                    <li>
                        <i className="fal fa-eye" /> 130 Views
                    </li>
                    <li>
                        <i className="fal fa-comments" /> 39 Comments
                    </li>
                    <li>
                        <i className="fal fa-calendar-alt" /> 3 Oct 2024
                    </li>
                    </ul>
                </div>
                <p>
                At Glean Cancer Centre and Multispeciality Hospital, a leading cancer hospital known for its compassionate care, we believe prevention is the best way to fight cancer. One key tool in prevention is genetic testing, which can help identify hereditary cancer risks. Understanding your genetic makeup allows you to take proactive steps, ensuring early detection and better outcomes.                </p>

                {/* <h3>
                What is Chemotherapy?
                </h3>
                <p>
                Chemotherapy (chemo) involves the use of drugs to kill or slow the growth of cancer cells. It is a cornerstone of cancer treatment that can be administered orally or intravenously to target cancer cells throughout the body. At Glean Hospital, our experts ensure that chemotherapy is tailored to minimize side effects while maximizing its effectiveness against cancerous cells.
                </p> */}

                {/* <blockquote>
                    <footer>The TNM Staging System Explained :</footer>
                    <h3>
                    The TNM system classifies cancer based on three factors:
                    </h3>
                </blockquote> */}
                
                    <h5> What is Genetic Testing for Cancer?</h5>
                   
                     <p>
                      Genetic testing checks for inherited mutations in certain genes that may increase your risk of developing cancer. Some cancers, such as breast, ovarian, and colon cancers, can run in families. Identifying these genetic mutations gives you and your healthcare provider insights into your risk level.
                      </p>
                    <h5>Who Should Consider Genetic Testing?</h5>
                    

                       <p>You should consider genetic testing if:                      </p>
<ul>
    <li>- Several family members have had cancer, especially at a young age.</li>
    <li>- There’s a history of rare cancers in your family, like ovarian or male breast cancer.</li>
    <li>- You're concerned about passing hereditary risks to future generations.</li>
</ul>
                      <h5>How Can Genetic Testing Help?</h5>

                     <p>
                     By knowing your genetic risks, you can take action:
                      </p>
                      <ul>
    <li>- Regular Screenings: Increased screenings like mammograms or colonoscopies can detect cancer early.</li>
    <li>- Preventive Care: You might explore preventive treatments or surgeries.</li>
    <li>- Family Planning: It helps in making informed decisions for future generations.</li>
</ul>
              
                
                <div className="details__content-img">
                    <img src={Blogdetails01} alt="" /> 
                </div>

                <h6>Glean Cancer Centre’s Comprehensive Support:</h6>      
                <p>As a multispeciality hospital , Glean Cancer Centre provides holistic care, from genetic counselling to palliative care and personalised prevention plans. Our expert counsellors guide you through every step of genetic testing and help you understand what your results mean for you and your family. With us, you're never alone in your fight against cancer.</p>
                <p>By choosing Glean Cancer Centre, a trusted hospital of cancer, you gain access to a world-class cancer hospital and support that goes beyond treatment, ensuring you and your family are empowered with the knowledge to take control of your health.</p>
                </div>
              

            </div>
        </div>

    </>
  )
}

export default six;