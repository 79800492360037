import React from 'react'
// import { useState } from 'react';
import Blogdetails01 from '../../../src/assets/img/blog/big.png'
import Blogdetails02 from '../../assets/img/blog/small.png'
// import Blogdetails03 from '../../assets/img/blog/b_details03.jpg'
// import Blogdetails04 from '../../assets/img/blog/b_details04.jpg'
// import AvatarOne from '../../assets/img/blog/comment/avatar.png'
// import Iconone from '../../assets/img/icon/c_d01.png'
// import CommentOne from '../../assets/img/blog/comment/c_01.png' 
// import CommentTwo from '../../assets/img/blog/comment/c_02.png'
// import Commentthree from '../../assets/img/blog/comment/c_03.png'
// import { Link } from 'react-router-dom'
// import LazyLoad from 'react-lazyload';



function fourth() {
  

  return (
    <>
        <div className="col-lg-10">
            <div className="blog-details-wrap">
                <div className="details__content pb-30">
                <h2>Understanding When Chemotherapy is Recommended for Cancer Treatment:</h2>
                <div className="meta-info">
                    <ul>
                    <li>
                        <i className="fal fa-eye" /> 175 Views
                    </li>
                    <li>
                        <i className="fal fa-comments" /> 39 Comments
                    </li>
                    <li>
                        <i className="fal fa-calendar-alt" /> 18 Oct 2024
                    </li>
                    </ul>
                </div>
                <p>
                At Glean Cancer Centre, a leading multispeciality hospital near you, we specialize in providing personalized cancer treatment. Chemotherapy is one of the most common treatments used for various stages of cancer, depending on the type, stage, and individual health factors. Our cancer hospital follows the American Joint Committee on Cancer's TNM staging system to ensure patients receive the best possible care.
                </p>

                <h3>
                What is Chemotherapy?
                </h3>
                <p>
                Chemotherapy (chemo) involves the use of drugs to kill or slow the growth of cancer cells. It is a cornerstone of cancer treatment that can be administered orally or intravenously to target cancer cells throughout the body. At Glean Hospital, our experts ensure that chemotherapy is tailored to minimize side effects while maximizing its effectiveness against cancerous cells.
                </p>

                <blockquote>
                    <footer>The TNM Staging System Explained :</footer>
                    <h3>
                    The TNM system classifies cancer based on three factors:
                    </h3>
                </blockquote>
                <p>
                     <b>T (Tumor):</b>  Size and spread of the primary tumor.
                     <br/>
                     <b>N (Nodes): </b>Spread to nearby lymph nodes.
                     <br/>
                     <b>M (Metastasis):</b> Spread to distant organs or body parts.
                </p>
                
                <div className="details__content-img">
                    <img src={Blogdetails01} alt="" /> 
                </div>

                <p>Based on these factors, cancers are grouped into stages from 1 to 4.</p>      
                <p><b>Chemotherapy and Cancer Stages : </b></p>
               
                <figure>
                    <img src={Blogdetails02} alt="" />
            <p>
               <b>1. Stage 1 – Early Stage Cancer : </b> 
                is localized and has not spread. While surgery or radiation is often the first option, chemotherapy may be recommended as adjuvant therapy post-surgery, especially in aggressive tumors, to ensure the remaining cancer cells are destroyed.
            </p>

                <p>
              <b> 2. Stage 2 – Localized but Larger Tumors : </b> 
              Chemotherapy may be recommended either before surgery (neoadjuvant therapy) to shrink the tumor or after surgery to prevent recurrence.
                </p>

                <p>
              <b>3. Stage 3 – Advanced Localized Cancer : </b> 
              
              In stage 3, chemotherapy is frequently part of the treatment, aiming to destroy cancer cells in both the primary site and nearby regions.
                </p>

                <p>
              <b>4. Stage 4 – Metastatic Cancer : </b> 
              For stage 4 cancer, which has spread to distant organs, chemotherapy is used as palliative care to manage symptoms, slow the cancer’s progression, and improve the patient's quality of life. While not curative, it plays a crucial role in extending life and alleviating discomfort.
                </p>
                    <h5>
                       Individual Considerations for Chemotherapy:
                    </h5>
                    <p>
                    Before recommending chemotherapy, our team at Glean Hospital assesses various factors, including the cancer type, location, growth rate, and the patient’s overall health. Every patient’s journey with cancer treatment is unique, and our specialists are committed to providing compassionate, comprehensive care throughout the process.
                    </p>

                    <h6>
                    Conclusion: 
                    </h6>
                    <p>
                    Chemotherapy is a key component of cancer treatment for patients at every stage of their cancer journey. Whether aimed at curing the disease in the early stages or providing palliative care for advanced-stage patients, the goal is to offer the best possible outcomes. Glean Cancer Centre, a trusted cancer hospital, ensures that every patient receives expert care tailored to their individual needs.
                     <br/>
               If you're seeking expert cancer treatment at a multispeciality hospital near you, or need guidance on palliative care for late-stage cancer, contact Glean Cancer Centre and book a consultation today.
                    </p>
                </figure>

               

                {/* {blogPosts.slice(1).map((post, index) => (
                   <LazyLoad key={index} height={200} offset={100} once>
                           <div className="blog-post">
                            <h2>{post.title}</h2>
                               <p>{post.content}</p>
                          </div>
                   </LazyLoad>
                    ))} */}
                
                {/* <div className="row">
                    <div className="col-xl-12 col-md-12">
                    <div className="post__tag">
                        <h5>Releted Tags</h5>
                        <ul>
                        <li>
                            <Link to="#">organic</Link>
                        </li>
                        <li>
                            <Link to="#">Foods</Link>
                        </li>
                        <li>
                            <Link to="#">tasty</Link>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div> */}
                </div>
                {/* <div className="posts_navigation pt-35 pb-35">
                <div className="row align-items-center">
                    <div className="col-xl-4 col-md-5">
                    <div className="prev-link">
                        <span>Prev Post</span>
                        <h4>
                        <Link to="#">Tips Minimalist</Link>
                        </h4>
                    </div>
                    </div>
                    <div className="col-xl-4 col-md-2 text-left text-md-center">
                    <Link to="/blog" className="blog-filter">
                        <img src={Iconone} alt="" />
                    </Link>
                    </div>
                    <div className="col-xl-4 col-md-5">
                    <div className="next-link text-left text-md-right">
                        <span>next Post</span>
                        <h4>
                        <Link to="#">Less Is More</Link>
                        </h4>
                    </div>
                    </div>
                </div>
                </div> */}
                {/* <div className="related__post mt-45 mb-85"> */}
                {/* <div className="post-title">
                    <h4>Related Post</h4>
                </div> */}
                {/* <div className="row">
                    <div className="col-md-6">
                    <div className="related-post-wrap mb-30">
                        <div className="post-thumb">
                        <img src={Blogdetails03} alt="" />
                        </div>
                        <div className="rp__content">
                        <h3>
                            <Link to="#">Auis nostrud exercita ullamco laboris nisi ut</Link>
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed
                            do eiusmod temp or incididunt ut labore et dolore.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="related-post-wrap mb-30">
                        <div className="post-thumb">
                        <img src={Blogdetails04} alt="" />
                        </div>
                        <div className="rp__content">
                        <h3>
                            <Link to="#">Excepteur sint occaecat cupida tat non proident</Link>
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed
                            do eiusmod temp or incididunt ut labore et dolore.
                        </p>
                        </div>
                    </div>
                    </div>
                </div> */}
                {/* </div>
                <div className="avatar__wrap text-center mb-45">
                <div className="avatar-img">
                    <img src={AvatarOne} alt="" />
                </div>
                <div className="avatar__info">
                    <h5>Rosalina William</h5>
                    <div className="avatar__info-social">
                    <Link to="#">
                        <i className="fab fa-facebook-f" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-twitter" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-instagram" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-behance" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-linkedin" />
                    </Link>
                    </div>
                </div>
                <div className="avatar__wrap-content">
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequa aute irure dolor.
                    </p>
                </div>
                </div> */}
                {/* <div className="comment__wrap pb-45">
                <div className="comment__wrap-title">
                    <h5>Comments</h5>
                </div>
                <div className="single__comment mb-35">
                    <div className="comments-avatar">
                    <img src={CommentOne} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>ALina Kelian</h6>
                        <span>19th May 2021</span>
                    </div>
                    <p>
                        The bee's knees bite your arm off bits and bobs he nicked it gosh
                        gutted mate blimey, old off his nut argy bargy vagabond buggered
                        dropped.
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                <div className="single__comment children mb-35">
                    <div className="comments-avatar">
                    <img src={CommentTwo} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>
                        Rlex Kelian <i className="fas fa-bookmark" />
                        </h6>
                        <span>19th May 2021</span>
                    </div>
                    <p>
                        Do one say wind up buggered bobby bite your arm off gutted mate,
                        David victoria sponge cup of char chap fanny around.
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                <div className="single__comment">
                    <div className="comments-avatar">
                    <img src={Commentthree} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>Roboto Alex</h6>
                        <span>21th May 2021</span>
                    </div>
                    <p>
                        Baking cakes is cobblers wellies William geeza bits and bobs what a
                        plonker it's your round,
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                </div> */}
                {/* <div id="comments" className="comments-area  mt-45">
                <div id="respond" className="comment-respond">
                    <h3 id="reply-title" className="comment-reply-title">
                    Leave a Reply{" "}
                    <small>
                        <Link rel="nofollow" id="cancel-comment-reply-link" to="/finco/?p=2112#respond" style={{ display: "none" }} >
                        Cancel reply
                        </Link>
                    </small>
                    </h3>
                    <form action="http://wordpress.zcube.in/finco/wp-comments-post.php" method="post" id="commentform" className="comment-form" noValidate="" >
                    <p className="comment-notes">
                        <span id="email-notes">
                        Your email address will not be published.
                        </span>{" "}
                        Required fields are marked <span className="required">*</span>
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-user" />
                        <input id="author" placeholder="Your Name" name="author" type="text" />
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-envelope" />
                        <input id="email" placeholder="your-real-email@example.com" name="email" type="text" />
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-globe" />
                        <input id="url" name="url" placeholder="http://your-site-name.com" type="text" />{" "}
                    </p>
                    <p className="comment-form-comment">
                        <label htmlFor="comment">Comment</label>{" "}
                        <textarea id="comment" name="comment" cols={45} rows={8} maxLength={65525} required="required" defaultValue={""} />
                    </p>
                    <p className="form-submit">
                        <input name="submit" type="submit" id="submit" className="submit" defaultValue="Post Comment" />{" "}
                        <input type="hidden" name="comment_post_ID" defaultValue={2112} id="comment_post_ID" />
                        <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                    </p>
                    </form>
                </div>
                </div> */}
            </div>
        </div>

    </>
  )
}

export default fourth;