import React from "react";
import { Link } from "react-router-dom";

function Blog() {
  const data = [
    {
      id: 9,
      name: "Understanding Stomach Cancer: Causes, Symptoms, and Care",
      img: "assets/img/blog/blog9.png",
      more: "Stomach cancer, though less common than other types, is a serious ...",
      date: "27 Nov 2024",
      link: "/blogdetails9"
    },
    {
      id: 8,
      name: "Understanding Lung Cancer: Causes, Symptoms, and Prevention.",
      img: "assets/img/blog/blog8.png",
      more: "Lung cancer is one of the most common types of cancer, yet it’s often...",
      date: "20 Nov 2024",
      link: "/blogdetails8"
    },
    {
      id: 7,
      name: "The Role of Caregivers: How to Support a Loved One Battling Cancer",
      img: "assets/img/blog/blog7.png",
      more: "When a loved one is diagnosed with cancer, caregivers become an essential part...",
      date: "16 Nov 2024",
      link: "/blogdetails7"
    },
    {
      id: 1,
      name: "How Cancer Centers Are Addressing Social Needs Patient",
      img: "assets/img/blog/inner_b1.png",
      more: "In the journey through cancer treatment, addressing the medical aspects is crucial,",
      date: "9 Nov 2024",
      link: "/blogdetails",
      
    },
    {
      id: 2,
      name: "Self-Collection Screening for Virus that Causes Cancer.",
      img: "assets/img/blog/inner_b2.png",
      more: "We are thrilled to share exciting news in the field of women’s health:",
      date: "2 Nov 2024",
      link: "/blogdetails2",
     
    },
    {
      id: 3,
      name: "Palliative care for individuals in their cancer journey.",
      img: "assets/img/blog/inner_b3.png",
      more: "Palliative care grants special attention for those living with a serious illness.",
      date: "25 oct 2024",
      link: "/blogdetails3",
    },
    {
      id: 4,
      name: "Understanding When Chemotherapy is Recommended for...",
      img: "assets/img/blog/blog4.png",
      more: "At Glean Cancer Centre, a leading multispeciality hospital near you, we specialize...",
      date: "18 Oct 2024",
      link: "/blogdetails4"
    },
    {
      id: 5,
      name: "Busting Common Cancer Myths: Get Real About Cancer",
      img: "assets/img/blog/blog5.png",
      more: "When it comes to cancer, myths and misconceptions are everywhere. One of the biggest? ",
      date: "11 oct 2024",
      link: "/blogdetails5"
    },
    {
      id: 6,
      name: "Understanding Cancer Genetics: Should You Consider Genetic Testing?",
      img: "assets/img/blog/blog6.png",
      more: "At Glean Cancer Centre and Multispeciality Hospital, a leading cancer hospital... ",
      date: "3 Oct 2024",
      link: "/blogdetails6"
    },
  ];
  return (
    <>
      <section id="blog" className="blog-area p-relative fix pt-90 pb-90">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-title center-align mb-50 text-center wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s">
                <h5>Our Blog</h5>
                <h2>Latest Blog &amp; News</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {data.map((item) => (
                <div className="col-lg-4 col-md-6" key={item.id}>
                  <div className="single-post2 hover-zoomin mb-30 wow fadeInUp animated"data-animation="fadeInUp"data-delay=".4s">
                    <div className="blog-thumb2">
                      <Link to="/blog">
                        <img src={item.img} alt="img" />
                      </Link>
                    </div>
                    <div className="blog-content2">
                      <div className="date-home">
                        <i className="fal fa-calendar-alt" /> {item.date}
                      </div>
                      <h4>
                        {/* <Link to="/blog">{item.name}</Link> */}
                        <Link to={item.link} target="blank">{item.name}</Link>
                      </h4>
                      <p>
                      {item.more}
                      </p>
                      <div className="blog-btn">
                        {/* <Link to={item.link}>Read More</Link> */}
                        <Link to={item.link}>Read More</Link>
                      </div>
                    </div> 
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
