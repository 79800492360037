import React from 'react'
import Blogdetails01 from '../../assets/img/blog/big2.png'
// import Blogdetails02 from '../../assets/img/blog/small.png'

function fifth() {
  

  return (
    <>
        <div className="col-lg-10">
            <div className="blog-details-wrap">
                <div className="details__content pb-30">
                <h2>Busting Common Cancer Myths: Get Real About Cancer</h2>
                <div className="meta-info">
                    <ul>
                    <li>
                        <i className="fal fa-eye" /> 139 Views
                    </li>
                    <li>
                        <i className="fal fa-comments" /> 9 Comments
                    </li>
                    <li>
                        <i className="fal fa-calendar-alt" /> 11 Oct 2024
                    </li>
                    </ul>
                </div>
                <p>
                When it comes to cancer, myths and misconceptions are everywhere. One of the biggest? That cancer is a "battle" you have to "fight" to survive. Let’s break down some of these myths and get to the truth.
                </p>

                {/* <h3>
                What is Chemotherapy?
                </h3>
                <p>
                Chemotherapy (chemo) involves the use of drugs to kill or slow the growth of cancer cells. It is a cornerstone of cancer treatment that can be administered orally or intravenously to target cancer cells throughout the body. At Glean Hospital, our experts ensure that chemotherapy is tailored to minimize side effects while maximizing its effectiveness against cancerous cells.
                </p> */}

                {/* <blockquote>
                    <footer>The TNM Staging System Explained :</footer>
                    <h3>
                    The TNM system classifies cancer based on three factors:
                    </h3>
                </blockquote> */}
                <p>
                    <h5> <b>Myth 1: </b> "Cancer is a Battle, and You Have to Fight to Win"</h5>
                   
                      <p>
                      We often hear that cancer is a battle, but framing it this way can make patients feel like they’re not doing enough if things don’t go as planned. The truth is, cancer is a disease—not a contest. It’s not about how hard you fight; it’s about following your treatment plan, staying strong when you can, and taking care of yourself.
                      </p>
                    <h5> <b>Myth 2: </b> "Your Personality Can Cause Cancer" </h5>
                    

                       <p>
                         This is an outdated idea. Cancer doesn’t care if you’re optimistic or pessimistic. It’s caused by factors like genetics and lifestyle, not personality traits. So no, being a "worrier" won’t give you cancer.
                      </p>

                      <h5>   <b>Myth 3:</b>"A Positive Attitude Will Cure Cancer" </h5>

                     <p>
                     While positivity can help with coping, it won’t cure cancer. You’re allowed to have bad days, and it’s okay to feel down sometimes. What’s important is taking positive action, attending appointments, following your treatment plan, and asking for help when needed.
                      </p>
                </p>
                
                <div className="details__content-img">
                    <img src={Blogdetails01} alt="" /> 
                </div>

                <h6>Busting the Myths, Building Support</h6>      
                <p>At Glean Cancer Centre, we believe in treating the whole person, mind, body, and spirit. Don’t let these cancer myths weigh you down. You’re not in a battle; you’re on a journey toward healing, and we’re here to support you every step of the way.</p>
                <p>Ready to learn more? Glean Cancer Centre is with you through the facts, not the fiction!</p>
                </div>
              

            </div>
        </div>

    </>
  )
}

export default fifth;