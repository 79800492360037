import React from 'react'
import Blogdetails01 from '../../assets/img/blog/Gleanbiggerblog8.png'
// import Blogdetails02 from '../../assets/img/blog/small.png'

function eight() {
  

  return (
    <>
        <div className="col-lg-10">
            <div className="blog-details-wrap">
                <div className="details__content pb-30">
                <h2>Understanding Lung Cancer: Causes, Symptoms, and Prevention</h2>
                <div className="meta-info">
                    <ul>
                    <li>
                        <i className="fal fa-eye" /> 190 Views
                    </li>
                    <li>
                        <i className="fal fa-comments" /> 78 Comments
                    </li>
                    <li>
                        <i className="fal fa-calendar-alt" /> 20 Nov 2024
                    </li>
                    </ul>
                </div>
                <p>
                Lung cancer is one of the most common types of cancer, yet it’s often misunderstood. At Glean, we believe that understanding this illness is the first step toward prevention, early detection, and treatment. Let’s take a closer look at lung cancer in a way that’s easy to understand, from its causes to what you can do to lower your risk.</p>

                <h3>
                   What is Lung Cancer?
                </h3>
                <p>
                Lung cancer begins when cells in the lungs start to grow uncontrollably. The lungs, which bring oxygen into our bodies and remove carbon dioxide, are vital organs. When abnormal cells grow in the lungs, they can form a tumor and interfere with lung function. If untreated, lung cancer can spread to other parts of the body, making it more challenging to treat.
                </p>

            <div>
                <h3>
                   What Causes Lung Cancer?
                </h3>
                <p>
                   Lung cancer doesn’t have a single cause, but several risk factors can increase the likelihood of developing it:
                </p>
             <ol>
                <li>
                <b>1. Smoking: </b> Cigarette smoke is the leading cause of lung cancer, responsible for about 85% of cases. Both smokers and those exposed to secondhand smoke are at high risk.
                </li>
                <li>
               <b>2. Exposure to Harmful Substances: </b> Asbestos, radon, and certain chemicals in the workplace can increase lung cancer risk. Radon, a radioactive gas that seeps from the ground, is particularly dangerous when it builds up indoors.
                </li>
                <li>
                <b>3. Genetics: </b> A family history of lung cancer can increase your risk, as certain genetic factors make some people more susceptible.
                </li>
                <li>
               <b>4. Air Pollution: </b> Prolonged exposure to polluted air can damage lung tissue, slightly increasing lung cancer risk.
                </li>               
             </ol>
           </div>
                <blockquote>
                    <footer>The TNM Staging System Explained :</footer>
                    <h3>
                    The TNM system classifies cancer based on three factors:
                    </h3>
                </blockquote>
                
                    <h3>What Are the Symptoms of Lung Cancer?</h3>         
                    <p>
                      Lung cancer symptoms can be subtle, especially in the early stages, which is why it often goes undetected until it’s advanced. Key symptoms include:
                    </p>

        <div>
             <ol>
                <li>
                <b>Persistent Cough: </b>A cough that doesn’t go away or worsens over time.
                </li>
                <li>
                  <b>Chest Pain: </b> Pain in the chest, especially during deep breathing, coughing, or laughing.
                </li>
                <li>
                <b>Shortness of Breath: </b>Feeling breathless or struggling to catch your breath.
                </li>
                <li>
                <b>Unexplained Weight Loss: </b>Sudden weight loss without trying.
                </li>   
                <li>
                <b>Fatigue: </b>Constant tiredness or low energy.
                </li>             
             </ol>
        </div>
                  

                     <p>
                     It’s essential to consult a doctor if you notice any of these symptoms, especially if you have risk factors for lung cancer.
                      </p>
                </div>  
                
                <div className="details__content-img">
                    <img src={Blogdetails01} alt="" /> 
                </div>

                 <h5>How Can You Lower Your Risk of Lung Cancer?</h5>
                     <p>While not all cases of lung cancer are preventable, there are steps you can take to reduce your risk:</p>           
             
                     <div>
             <ol>
                <li>
                <b>1. Quit Smoking: </b>If you smoke, quitting is the best thing you can do to reduce your lung cancer risk. 
                <b>Avoid Secondhand Smoke: </b> If you’re around smokers, encourage them to smoke outside or find ways to limit exposure to secondhand smoke.
                </li>
                <li>
                <b>2. Test for Radon: </b> Test your home for radon, especially if you live in an area where radon levels are known to be high. Radon test kits are available, and professional radon mitigation services can help if high levels are detected.
                </li>
                <li>
                <b>3. Practice Workplace Safety: </b>If you work with chemicals or materials that increase lung cancer risk, use protective equipment and follow safety protocols.
                </li>
                <li>
                <b>4. Exercise and Eat Healthily: </b> A healthy lifestyle that includes regular physical activity and a balanced diet can strengthen your immune system and lower cancer risk.
                </li>   
                           
             </ol>
        </div>


               <div>
                  <h5>How Glean Can Help: </h5>
                  <p>At Glean Cancer Centre, we’re committed to supporting those at risk of or diagnosed with lung cancer. Our comprehensive services include:</p>
               </div>
               <div>            
                  <p><b>Screening and Early Detection: </b>For high-risk individuals, regular screenings like low-dose CT scans can detect lung cancer early, when it’s most treatable.</p>
               </div>
               <div>            
                  <p><b>Personalised Care : </b> We provide personalised guidance and care tailored to each patient’s needs, helping you manage risk and treatment with expert support.</p>
               </div>
               <div>              
                  <p><b>Support and Counseling: </b> Facing a cancer diagnosis is challenging. Our counseling services offer emotional and mental health support for patients and families at every step of the journey along with the much needed palliative care for some.</p>
               </div>
            </div>
        </div>

       <div>
        <h4>Take Control of Your Health Today With us</h4>
        <p>Lung cancer can feel overwhelming, but knowing the risks and symptoms and taking proactive steps can make a big difference. Whether it’s getting a check-up, asking questions about your risk, or scheduling a screening, small actions can lead to big changes.
        </p>
        <p>If you or a loved one wants to learn more about lung cancer prevention, screening, or treatment, Glean Cancer Centre is here to help. Contact us today to start your journey toward better health and a better understanding of lung cancer.</p>
       </div>

    </>
  )
}

export default eight;