import React from 'react'
// import { useState } from 'react';
import Blogdetails04 from '../../../src/assets/img/blog/b_details03.png'
import Blogdetails02 from '../../../src/assets/img/blog/sb2.png'
// import Blogdetails02 from '../../assets/img/blog/s.png'
// import Blogdetails03 from '../../assets/img/blog/b_details03.jpg'
// import Blogdetails04 from '../../assets/img/blog/b_details04.jpg'
// import AvatarOne from '../../assets/img/blog/comment/avatar.png'
// import Iconone from '../../assets/img/icon/c_d01.png'
// import CommentOne from '../../assets/img/blog/comment/c_01.png' 
// import CommentTwo from '../../assets/img/blog/comment/c_02.png'
// import Commentthree from '../../assets/img/blog/comment/c_03.png'
// import { Link } from 'react-router-dom'
// import LazyLoad from 'react-lazyload';



function Second() {
  

  return (
    <>
        <div className="col-lg-10">
            <div className="blog-details-wrap">
                <div className="details__content pb-30">
                  <h2>
                FDA Approves Self-Collection Screening for Virus that Causes Cervical Cancer 
                </h2>
                <div className="meta-info">
                    <ul>
                    <li>
                        <i className="fal fa-eye" /> 100 Views
                    </li>
                    <li>
                        <i className="fal fa-comments" /> 67 Comments
                    </li>
                    <li>
                        <i className="fal fa-calendar-alt" /> 2 Nov 2024
                    </li>
                    </ul>
                </div>
                <p>
                We are thrilled to share exciting news in the field of women’s health: the FDA has recently approved self-collection screening for the virus that causes cervical cancer. This development marks a significant advancement in cervical cancer prevention, offering women a convenient and accessible option for early detection.

                </p>
                <p>
                Understanding the Importance of Cervical Cancer Screening
                  Cervical cancer is primarily caused by certain types of the human papillomavirus (HPV). Regular screening for HPV and cervical abnormalities is crucial for early detection and treatment, which significantly improves outcomes. Traditionally, screening has involved a pelvic exam and cervical swab performed by a healthcare provider.
                </p>
                <blockquote>
                    <footer>Advertising</footer>
                    <h3>
                    Do you want a website for your business? 
                    Then, Sasahyog Technologies has got your back.
                    </h3>
                </blockquote>
                
                <p>
                 <b>What Does Self-Collection Screening Involve?</b><br/>The newly approved method allows women to collect a vaginal sample themselves at home using a self-collection kit. This sample is then sent to a lab for HPV testing. Studies have shown that self-collection is as effective as provider-collected samples in detecting high-risk HPV infections.
                   
                </p>
                <p>
               <b>Benefits of Self-Collection Screening:</b><br/><b>Convenience:</b>  Women can perform the test in the privacy of their homes at a time that suits them, eliminating the need for a clinic visit.
      
                </p>
                <p>
               <b>Accessibility: </b> This method improves access to screening, particularly for those who face barriers such as transportation issues, time constraints, or discomfort with pelvic exams.
                </p>
                <div className="details__content-img">
                    <img src={Blogdetails04} alt="" /> 
                </div>
                <p>
               <b>Accuracy:</b>  Research supports the accuracy of self-collected samples in detecting HPV, making it a reliable screening option.
                </p>
                <p>
               <b>Who Should Consider Self-Collection Screening?</b> <br/><b>Women aged 30 and older:</b>Routine HPV testing is recommended for women in this age group, along with Pap smears.   
                </p>
                <p>
               <b>Women with limited access to healthcare:</b>Self-collection can be particularly beneficial for women in rural or underserved areas.


                </p>
                
                <p>
               <b>Our Commitment to Women’s Health</b><br/>At Glean Cancer Centre and Multi-speciality Hospital , we are committed to promoting women’s health and offering innovative solutions that enhance early detection and prevention of cervical cancer. With the FDA’s approval of self-collection screening, we are excited to expand our screening options, providing women with a choice that fits their needs and preferences.

                </p>
               
                <figure>
                    <img src={Blogdetails02} alt="" />
                    <p>
              <b>Next Steps </b> 
              If you are interested in self-collection screening for cervical cancer or have questions about your screening options, our dedicated healthcare team is here to support you. We encourage all women to prioritize their health by staying informed and proactive about cervical cancer prevention.
                </p>
                    <p>
                    For more information or to schedule a consultation, please contact [Hospital Name] at [contact information]. Together, we can continue to empower women with the tools and knowledge they need to take charge of their health and well-being.
                    </p>
                    <p>
                    Stay tuned to our website and social media channels for updates on advancements in women’s health and important healthcare news.

                   Let’s work together towards a future where cervical cancer is preventable through accessible and effective screening methods. Your health matters to us.
                    </p>
                </figure>

                {/* {blogPosts.slice(1).map((post, index) => (
                   <LazyLoad key={index} height={200} offset={100} once>
                           <div className="blog-post">
                            <h2>{post.title}</h2>
                               <p>{post.content}</p>
                          </div>
                   </LazyLoad>
                    ))} */}
                
                {/* <div className="row">
                    <div className="col-xl-12 col-md-12">
                    <div className="post__tag">
                        <h5>Releted Tags</h5>
                        <ul>
                        <li>
                            <Link to="#">organic</Link>
                        </li>
                        <li>
                            <Link to="#">Foods</Link>
                        </li>
                        <li>
                            <Link to="#">tasty</Link>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div> */}
                </div>
                {/* <div className="posts_navigation pt-35 pb-35">
                <div className="row align-items-center">
                    <div className="col-xl-4 col-md-5">
                    <div className="prev-link">
                        <span>Prev Post</span>
                        <h4>
                        <Link to="#">Tips Minimalist</Link>
                        </h4>
                    </div>
                    </div>
                    <div className="col-xl-4 col-md-2 text-left text-md-center">
                    <Link to="/blog" className="blog-filter">
                        <img src={Iconone} alt="" />
                    </Link>
                    </div>
                    <div className="col-xl-4 col-md-5">
                    <div className="next-link text-left text-md-right">
                        <span>next Post</span>
                        <h4>
                        <Link to="#">Less Is More</Link>
                        </h4>
                    </div>
                    </div>
                </div>
                </div> */}
                {/* <div className="related__post mt-45 mb-85"> */}
                {/* <div className="post-title">
                    <h4>Related Post</h4>
                </div> */}
                {/* <div className="row">
                    <div className="col-md-6">
                    <div className="related-post-wrap mb-30">
                        <div className="post-thumb">
                        <img src={Blogdetails03} alt="" />
                        </div>
                        <div className="rp__content">
                        <h3>
                            <Link to="#">Auis nostrud exercita ullamco laboris nisi ut</Link>
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed
                            do eiusmod temp or incididunt ut labore et dolore.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="related-post-wrap mb-30">
                        <div className="post-thumb">
                        <img src={Blogdetails04} alt="" />
                        </div>
                        <div className="rp__content">
                        <h3>
                            <Link to="#">Excepteur sint occaecat cupida tat non proident</Link>
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed
                            do eiusmod temp or incididunt ut labore et dolore.
                        </p>
                        </div>
                    </div>
                    </div>
                </div> */}
                {/* </div>
                <div className="avatar__wrap text-center mb-45">
                <div className="avatar-img">
                    <img src={AvatarOne} alt="" />
                </div>
                <div className="avatar__info">
                    <h5>Rosalina William</h5>
                    <div className="avatar__info-social">
                    <Link to="#">
                        <i className="fab fa-facebook-f" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-twitter" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-instagram" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-behance" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-linkedin" />
                    </Link>
                    </div>
                </div>
                <div className="avatar__wrap-content">
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequa aute irure dolor.
                    </p>
                </div>
                </div> */}
                {/* <div className="comment__wrap pb-45">
                <div className="comment__wrap-title">
                    <h5>Comments</h5>
                </div>
                <div className="single__comment mb-35">
                    <div className="comments-avatar">
                    <img src={CommentOne} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>ALina Kelian</h6>
                        <span>19th May 2021</span>
                    </div>
                    <p>
                        The bee's knees bite your arm off bits and bobs he nicked it gosh
                        gutted mate blimey, old off his nut argy bargy vagabond buggered
                        dropped.
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                <div className="single__comment children mb-35">
                    <div className="comments-avatar">
                    <img src={CommentTwo} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>
                        Rlex Kelian <i className="fas fa-bookmark" />
                        </h6>
                        <span>19th May 2021</span>
                    </div>
                    <p>
                        Do one say wind up buggered bobby bite your arm off gutted mate,
                        David victoria sponge cup of char chap fanny around.
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                <div className="single__comment">
                    <div className="comments-avatar">
                    <img src={Commentthree} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>Roboto Alex</h6>
                        <span>21th May 2021</span>
                    </div>
                    <p>
                        Baking cakes is cobblers wellies William geeza bits and bobs what a
                        plonker it's your round,
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                </div> */}
                {/* <div id="comments" className="comments-area  mt-45">
                <div id="respond" className="comment-respond">
                    <h3 id="reply-title" className="comment-reply-title">
                    Leave a Reply{" "}
                    <small>
                        <Link rel="nofollow" id="cancel-comment-reply-link" to="/finco/?p=2112#respond" style={{ display: "none" }} >
                        Cancel reply
                        </Link>
                    </small>
                    </h3>
                    <form action="http://wordpress.zcube.in/finco/wp-comments-post.php" method="post" id="commentform" className="comment-form" noValidate="" >
                    <p className="comment-notes">
                        <span id="email-notes">
                        Your email address will not be published.
                        </span>{" "}
                        Required fields are marked <span className="required">*</span>
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-user" />
                        <input id="author" placeholder="Your Name" name="author" type="text" />
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-envelope" />
                        <input id="email" placeholder="your-real-email@example.com" name="email" type="text" />
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-globe" />
                        <input id="url" name="url" placeholder="http://your-site-name.com" type="text" />{" "}
                    </p>
                    <p className="comment-form-comment">
                        <label htmlFor="comment">Comment</label>{" "}
                        <textarea id="comment" name="comment" cols={45} rows={8} maxLength={65525} required="required" defaultValue={""} />
                    </p>
                    <p className="form-submit">
                        <input name="submit" type="submit" id="submit" className="submit" defaultValue="Post Comment" />{" "}
                        <input type="hidden" name="comment_post_ID" defaultValue={2112} id="comment_post_ID" />
                        <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                    </p>
                    </form>
                </div>
                </div> */}
            </div>
        </div>

    </>
  )
}

export default Second